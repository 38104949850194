import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Bell,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { getAxiosHeaders } from "../../misc/utils";
import { baseURLs } from "../../misc/constants";
import axios from "axios";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";

export const OrderDetails = () => {
  const { orderID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [orderInfo, setOrderInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage('');
  };

  const getOrderInfo = () => {
    axios.get(`${baseURLs.API_URL}/orders/${orderID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setOrderInfo(responseInfo.data.order_info);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    if(!orderID){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getOrderInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Order Details" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Order Details - {`#${orderID}`}</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/orders`} />
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="md:col-span-5">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
            <div className="md:col-span-7">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
          </div>
          :
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="grid md:col-span-5 gap-8">
              <Card>
                <CardHeader>
                  <CardTitle className="text-md">Customer Info</CardTitle>
                </CardHeader>
                <CardContent className="gap-8">
                  <div>
                    <span className="text-sm font-medium text-gray-500">Name:</span>
                    <p className="text-lg font-semibold text-gray-800">{orderInfo.customer_name}</p>
                  </div>
                  <div>
                    <span className="text-sm font-medium text-gray-500">Phone Number:</span>
                    <p className="text-lg font-semibold text-gray-800">{orderInfo.customer_phone_number}</p>
                  </div>
                </CardContent>
              </Card>
              {
                orderInfo.images.length > 0 &&
                <Card>
                  <CardHeader>
                    <CardTitle className="text-md">Uploaded Images</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-3 gap-4">
                      {/* Image thumbnails */}
                      {
                        orderInfo.images.map((image, index) => {
                          return (
                            <img src={image} key={index}
                              className="cursor-pointer w-24 h-24 object-cover rounded-md border border-gray-200"
                              onClick={() => openModal(image)}
                            />
                          )
                        })
                      }                      
                    </div>
                  </CardContent>
                </Card>
              }

              <Card>
                <CardHeader>
                  <CardTitle className="text-md">Invoices</CardTitle>
                </CardHeader>
                <CardContent>
                  
                </CardContent>
              </Card>

              {/* Modal for Image Pop-up */}
              {modalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="relative">
                    <img src={currentImage} alt="Selected" className="max-h-[500px] object-contain rounded-md" />
                    <button
                      onClick={closeModal}
                      className="absolute top-2 right-2 text-white text-3xl"
                    >
                      &times;
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="md:col-span-7">
              <div className="grid md:col-span-5 gap-8">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-md">Order Status</CardTitle>
                  </CardHeader>
                  <CardContent>
                    
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle className="text-md">Products</CardTitle>
                  </CardHeader>
                  <CardContent>
                    
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        }
      </main>
    </React.Fragment>
  )
}
