import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Bell,
  PackageCheck,
  PackageOpen,
  ShoppingCart,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Badge } from "../../components/ui/badge";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Skeleton, TableSkeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { NoResults } from "../../components/ui/alert";
import moment from "moment";
import { getAxiosHeaders, OrderStatus } from "../../misc/utils";

export const Orders = () => {
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      pending_orders: {
        total: 0
      },
      delivered_orders: 0
    },
    data: []
  });
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const getOrders = () => {
    axios.get(baseURLs.API_URL + "/orders", {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setTableData({
          meta: responseInfo.data.meta,
          data: responseInfo.data.orders,
        });
      }

      setLoadingOrders(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingOrders(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    getOrders();
  }, [])

  return (
    <React.Fragment>
      <Head title="Orders" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Orders</h1>
          <Link to="/orders/add"><Button>Create New Order</Button></Link>
        </div>

        {
          loadingOrders ?
          <>
            <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
              <Skeleton className="h-[160px] rounded-xl" />
              <Skeleton className="h-[160px] rounded-xl" />
              <Skeleton className="h-[160px] rounded-xl" />
            </div>
            <Card x-chunk="dashboard-05-chunk-3">
              <TableSkeleton />
            </Card>
          </>
          :
          <>
            <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
              <Card x-chunk="dashboard-01-chunk-0">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Orders
                  </CardTitle>
                  <ShoppingCart className="h-8 w-8 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{tableData.meta.total_records}</div>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-01-chunk-1">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Pending Orders
                  </CardTitle>
                  <PackageOpen className="h-8 w-8 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{tableData.meta.pending_orders?.total}</div>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-01-chunk-2">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Completed Orders</CardTitle>
                  <PackageCheck className="h-8 w-8 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{tableData.meta.delivered_orders}</div>
                </CardContent>
              </Card>
            </div>

            <Card x-chunk="dashboard-05-chunk-3">
              <CardHeader className="px-7">
                <CardTitle className="text-lg">{tableData.meta.total_record} {tableData.meta.total_record > 1 ? `Orders` : `Order`}</CardTitle>
                {/* <CardDescription>
                  Recent orders.
                </CardDescription> */}
              </CardHeader>
              <CardContent>
                {
                  tableData.data.length === 0 ?
                  <NoResults message="No orders found" />
                  :
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead># Order ID</TableHead>
                        <TableHead>Customer</TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Status
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Type
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Total
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Date
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        tableData.data.map((data, index) => {
                          return (
                            <TableRow key={index} className="cursor-pointer" onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${data.order_id}`)}>
                              <TableCell>
                                #{data.order_id}
                              </TableCell>
                              <TableCell>
                                <div className="font-medium">
                                  <div className="font-medium">{data.customer_name}</div>
                                  <div className="hidden text-sm text-muted-foreground md:inline">
                                    {data.customer_phone_number}
                                  </div>
                                </div>
                                <div className="hidden text-sm text-muted-foreground md:inline">
                                {data.phone_number}
                                </div>
                              </TableCell>
                              <TableCell className="hidden sm:table-cell capitalize font-bold">
                                <OrderStatus status={data.status} />
                              </TableCell>
                              <TableCell className="hidden sm:table-cell">
                                <Badge className="text-xs capitalize" variant="secondary">
                                {`${data.order_type} order`}
                                </Badge>
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                GHS {data.total_amount}
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {moment(data.updated_at).format("Do MMM YYYY hh:mm a")}
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                      
                    </TableBody>
                  </Table>
                }
              </CardContent>
            </Card>
          </>
        }
      </main>
    </React.Fragment>
  )
}
