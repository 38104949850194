import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Bell,
  FileUp,
  PackageCheck,
  PackageOpen,
  Plus,
  Search,
  ShoppingCart,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Badge } from "../../components/ui/badge";
import { getAxiosHeaders, getQueryParams } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { TableSkeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { NoResults } from "../../components/ui/alert";
import { Checkbox } from "../../components/ui/checkbox";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../components/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import moment from "moment";

export const Deals = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
    },
    data: []
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [filters, setFilters] = useState({    
    page: 1,
    product_name: '',
    start_date: '',
    end_date: '',
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const resetFilter = () => {
    let params = {
      page: 1,
      product_name: '',
      start_date: '',
      end_date: '',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getDeals(params);
  }

  const filterDeals = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getDeals(filters);
  }

  // Toggle "select all" functionality
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allRowIds = tableData.data.map((deal, index) => deal.deal_id);
      setSelectedRows(allRowIds);
      setCheckAll(true);
    } else {
      // If unchecked, clear all selected rows
      setSelectedRows([]);
      setCheckAll(false);
    }
  };

  // Toggle individual row selection
  const handleRowSelect = (deal_id) => {
    if (selectedRows.includes(deal_id)) {
      // Remove row from selection if already selected
      setSelectedRows(selectedRows.filter((row) => row !== deal_id));
    } else {
      // Add row to selection if not already selected
      setSelectedRows([...selectedRows, deal_id]);
    }
  };

  const navigate = useNavigate();

  const getDeals = (filters) => {
    setIsLoading(true);
    axios.get(baseURLs.API_URL + "/deals", {
      headers: getAxiosHeaders().headers,
      params: {
        page: filters.page,
        product_name: filters.product_name,
        start_date: filters.start_date,
        end_date: filters.end_date,
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setTableData({
          meta: responseInfo.data.meta,
          data: responseInfo.data.deals,
        });

        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
      } else {
        setTableData({
          meta: {
            total_records: 0,
          },
          data: []
        })
      }

      
      currentUrl(filters);
      setIsLoading(false);
            
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setIsLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  // Delete all selected rows
  const handleDeleteSelected = (dealIDs = selectedRows) => {
    if (dealIDs.length === 0) {
        setErrorMessage(`Select at least one deal to delete`);
        toggleModal('errorDialog');
    } else {
        setSelectedRows(dealIDs); // Update selectedRows only if necessary
        toggleModal('deleteDealDialog');
    }
  };

  const handleSingleDelete = (dealID) => {
    handleDeleteSelected([dealID]);
  };

  const confirmDelete = () => {
    checkAll ? deleteDealsByFilter() : deleteDealsByIDs();    
  };

  const deleteDealsByIDs = () => {
    setRequesting(true);

    let _checkedBoxes = selectedRows;

    axios.delete(baseURLs.API_URL + "/deals/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        deals: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_deal_ids;
      let failedIDs = responseInfo.data.failed_deal_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getDeals(filters);        
        toggleModal('successDialog');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getDeals(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `deals` : `deal`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `deals` : `deal`} failed to get deleted`);
        toggleModal('errorDialog');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `deals` : `deal`}. The selected ${failedIDs.length > 1 ? `deals were` : `deal was`} not found.`);
        toggleModal('errorDialog');
      }

      setSelectedRows([]);
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const deleteDealsByFilter = () => {
    setRequesting(false);

    axios.delete(baseURLs.API_URL + "/deals/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        product_name: filters.product_name,
        start_date: filters.start_date,
        end_date: filters.end_date
      }
    })
    .then((response) => {
      getDeals(filters);
      toggleModal('successDialog');
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const currentUrl = (filters) => {
    let product_name = filters.product_name.length > 0 ? `&ps=${filters.product_name}` : ``;
    let date_range = filters.start_date !== null && filters.end_date !== null ? `&r=${filters.start_date},${filters.end_date}` : ``;
    
    if( (product_name !== '' ||
      date_range !== '' ) && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${product_name}${date_range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    navigate(url, { replace: true })
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getDeals(params)
  }

  const allSelected = selectedRows.length === tableData.data.length;

  useEffect(() => {
    setCheckAll(allSelected)
  },[allSelected])

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let product_name = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let date_range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let startDate = null;
    let endDate = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      startDate = new Date(rangeSplit[0]);
      endDate = new Date(rangeSplit[1]);
      setStartDate(startDate);
      setEndDate(endDate);
    }

    let params = filters;
    params.page = page;
    params.product_name = product_name;
    params.start_date = startDate;
    params.end_date = endDate;

    getDeals(params);
  }, [])

  return (
    <React.Fragment>
      <Head title="Deals" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Deals</h1>
          <div className="flex gap-x-2">
            <Link to="/deals/add"><Button><Plus className="mr-2" /> Create a New Deal</Button></Link>
          </div>
        </div>

        {
          isLoading ?
          <Card x-chunk="dashboard-05-chunk-3">
            <TableSkeleton />
          </Card>
          :
          <Card x-chunk="dashboard-05-chunk-3">
            <CardHeader className="px-7 flex-row justify-between">
              <CardTitle className="text-lg content-center">{tableData.meta.total_records} {tableData.meta.total_records > 1 ? `Deals` : `Deal`}</CardTitle>
              <Button variant="outline" onClick={() => toggleModal("filterModal")}><Search className="w-4 h-4 mr-2"  />Filter</Button>
            </CardHeader>
            <CardContent>
              {
                tableData.data.length === 0 ?
                <NoResults message="No deals found" />
                :
                <>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          <Checkbox checked={allSelected} onCheckedChange={handleSelectAll} />
                        </TableHead>
                        <TableHead className="hidden w-[100px] sm:table-cell">
                          <span className="sr-only">Image</span>
                        </TableHead>
                        <TableHead>Product Name</TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Unit Cost
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          MOQ
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Start - End Date
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Service Rate
                        </TableHead>
                        <TableHead>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost">
                                <DotsVerticalIcon className="h-5 w-5" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem onClick={handleDeleteSelected}>
                                Delete Selected
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        tableData.data.map((data, index) => {
                          const isSelected = selectedRows.includes(data.deal_id);

                          return (
                            <TableRow key={index} className="cursor-pointer">
                              <TableCell>
                                <Checkbox
                                  checked={isSelected}
                                  onCheckedChange={() => handleRowSelect(data.deal_id)}
                                />
                              </TableCell>
                              <TableCell className="hidden sm:table-cell" onClick={() => navigate(`${process.env.PUBLIC_URL}/deals/details/${data.deal_id}`)}>
                                <img
                                  alt={data.product_name}
                                  className="aspect-square rounded-md object-cover"
                                  height="64"
                                  src={data.thumbnail}
                                  width="64"
                                />
                              </TableCell>
                              <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/deals/details/${data.deal_id}`)}>
                                <div>{data.product_name}</div>
                                {
                                  data.code &&
                                  <small>CODE: <b>{data.code}</b></small>
                                }
                              </TableCell>
                              <TableCell className="hidden sm:table-cell capitalize font-bold">
                                {`¥ ${data.unit_cost} ~ GHS ${data.ghs_cost}`}
                              </TableCell>
                              <TableCell className="hidden sm:table-cell">
                                <b>{data.moq} {data.unit}</b> MOQ
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                <div className="mb-2">{`${moment(data.start_date).format("Do MMM YYYY")} - ${moment(data.end_date).format("Do MMM YYYY")}`}</div>
                                <Badge className="text-xs capitalize" variant={`${data.status === 'active' ? `default` : `destructive`}`}>
                                  {`${data.status}`}
                                </Badge>
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {`${data.service_rate}%`}
                              </TableCell>
                              <TableCell>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="ghost">
                                      <DotsVerticalIcon className="h-5 w-5" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent>
                                    {
                                      data.status === 'active' &&
                                      <DropdownMenuItem onClick={() => navigate(`${process.env.PUBLIC_URL}/deals/edit/${data.deal_id}`)}>
                                        Edit
                                      </DropdownMenuItem>
                                    }
                                    <DropdownMenuItem onClick={() => navigate(`${process.env.PUBLIC_URL}/deals/details/${data.deal_id}`)}>
                                      View Details
                                    </DropdownMenuItem>
                                    <hr/>
                                    <DropdownMenuItem className="text-red-500" onClick={() => handleSingleDelete(data.deal_id)}>
                                      Delete
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                  <div className="mt-5 justify-center">
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                  </div>
                </>
              }
            </CardContent>
          </Card>
        }
        {
          activeModal === "deleteDealDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedRows.length === 1 ? "Delete Deal" : "Delete Selected Deals"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedRows.length === 1
                    ? "Are you sure you want to delete this deal? This action cannot be undone."
                    : "Are you sure you want to delete all selected deals? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Deleting...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={confirmDelete}>Confirm</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  The deal(s) deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "errorDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal("errorDialog")}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Error</AlertDialogTitle>
                <AlertDialogDescription>
                  {errorMessage}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === 'filterModal' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader className="mb-4">
                <DialogTitle>Filter Deals</DialogTitle>
              </DialogHeader>
                <div className="space-y-4">
                  <div className="grid gap-2">
                    <Label htmlFor="product_name">Product Name</Label>
                    <Input
                      id="product_name"
                      name="product_name"
                      type="text"
                      placeholder="Enter a product name or tag"
                      onChange={onInputChange}
                      defaultValue={filters.product_name}
                    />
                  </div>
                </div>

                {/* Dialog Footer */}
                <DialogFooter>
                  <Button onClick={(ev) => { ev.preventDefault(); filterDeals();} }>Apply Filter</Button>
                  {
                    hasFilters &&
                    <Button className="ms-3" variant="secondary" onClick={(ev) => { ev.preventDefault(); resetFilter();} }>
                      Reset Filter
                    </Button>
                  }
                </DialogFooter>
            </DialogContent>
          </Dialog>
        }
      </main>
    </React.Fragment>
  )
}
