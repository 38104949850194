import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  CircleX,
  Cross,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { getAxiosHeaders, getAxiosUploadHeaders } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import ProductSelect from "../components/ProductSelect";
import { DatePicker } from "../components/DatePicker";
import moment from "moment";
import { FormSkeleton } from "../../components/ui/skeleton";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";

export const EditDeal = () => {
  const { dealID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [productInfo, setProductInfo] = useState({
    thumbnail: "",
    product_name: "",
    unit_cost: "",
    ghs_cost: "",
    moq: "",
  });
  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      start_date: "",
      end_date: "",
      service_rate: ""
    },
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const { handleSubmit, control, formState: { errors }, reset, setError } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

  
    axios.put(baseURLs.API_URL + `/deals/edit/${dealID}`, {
      product_id: data.product_id,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
      service_rate: data.service_rate
    }, getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        toggleModal("successDialog");
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors.start_date) {
            setError('start_date', {
              type: 'server',
              message: responseErrors.start_date
            });
          }

          if (responseErrors.end_date) {
            setError('end_date', {
              type: 'server',
              message: responseErrors.end_date
            });
          }
  
          if (responseErrors.service_rate) {
            setError('service_rate', {
              type: 'server',
              message: responseErrors.service_rate
            });
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };  

  const getDealInfo = () => {
    axios.get(`${baseURLs.API_URL}/deals/${dealID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }
      let dealInfo = responseInfo.data.deal_info;
      
      setProductInfo({
        thumbnail: dealInfo.thumbnail,
        product_name: dealInfo.product_name,
        unit_cost: dealInfo.unit_cost,
        ghs_cost: dealInfo.ghs_cost,
        moq: dealInfo.moq,
      });

      reset({
        start_date: moment(dealInfo.start_date) ?? "",
        end_date: moment(dealInfo.end_date) ?? "",
        service_rate: dealInfo.service_rate ?? "",
      })

      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    if(!dealID){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getDealInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Edit Deal" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Edit Deal</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/deals`} />
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
            <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
              <FormSkeleton />
            </div>
          </div>
          :
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
                <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
                  {
                    errorMessage &&
                    <Alert variant="destructive">
                      <AlertCircle className="h-4 w-4" />
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>
                        {errorMessage}
                      </AlertDescription>
                    </Alert>
                  }
                  <Card>
                    <CardContent className="pt-6 grid gap-6">
                      {/* product Field */}
                      <div className="max-h-60 overflow-y-auto">
                        <div className="flex items-center gap-4 py-6">
                          <img
                            src={productInfo.thumbnail}
                            alt={productInfo.product_name}
                            className="w-20 h-20 rounded"
                          />
                          <div>
                            <p className="text-lg font-medium">{productInfo.product_name}</p>
                            <p className="text-md text-gray-500">{`¥ ${productInfo.unit_cost} ~ GHS ${productInfo.ghs_cost} | MOQ: ${productInfo.moq}`}</p>
                          </div>
                        </div>
                      </div>

                      <hr/>

                      {/* Start Date & End Date */}
                      <div className="grid grid-cols-2 gap-6 pt-6">
                        {/* Start Date */}
                        <FormField
                          name="start_date"
                          control={control}
                          rules={{
                            required: "Start date is required",
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Start Date</FormLabel>
                              <FormControl>
                                <DatePicker field={field} />
                              </FormControl>
                              {errors.start_date && <FormMessage>{errors.start_date.message}</FormMessage>}
                            </FormItem>
                          )}
                        />

                        {/* End Date */}
                        <FormField name="end_date" control={control}
                          rules={{
                            required: "End date is required",
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>End Date</FormLabel>
                              <FormControl>
                                <DatePicker field={field} />
                              </FormControl>
                              {errors.end_date && <FormMessage>{errors.end_date.message}</FormMessage>}
                            </FormItem>
                          )}
                        />
                      </div>

                      {/* Service Rate */}
                      <div className="grid grid-cols-2 gap-6">
                        <FormField name="service_rate" control={control}
                          rules={{
                            required: "Service rate is required",
                            validate: {
                              isPercentage: (value) =>
                                value >= 0 && value <= 100
                                  ? true
                                  : "Service rate must be a percentage (0-100)",
                            },
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Service Rate</FormLabel>
                              <FormControl>
                                <div className="relative flex items-center">
                                  <Input
                                    {...field}
                                    type="number"
                                    step="0.01"
                                    className="pr-10" // Add space for the suffix
                                  />
                                  <span className="absolute right-3 text-gray-500 pointer-events-none">
                                    %
                                  </span>
                                </div>
                              </FormControl>
                              {errors.service_rate && <FormMessage>{errors.service_rate.message}</FormMessage>}
                            </FormItem>
                          )}
                        />
                      </div>
                      
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="pt-6">
                      <Button type="submit" className="w-full" disabled={requesting}>
                        {requesting ? "Submitting..." : "Submit"}
                      </Button>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </form>
          </FormProvider>
        }

        {/* Dialog Component */}
        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deal Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The deal has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
                <AlertDialogAction onClick={()=>{
                  navigate(`${process.env.PUBLIC_URL}/deals/details/${dealID}`);
                }}>View Deal</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }
      </main>
    </React.Fragment>
  )
}
