import { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog"
import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { Ship, Plane, AlertCircle } from 'lucide-react'
import { baseURLs } from "../../misc/constants"
import axios from "axios"
import { getAxiosHeaders } from "../../misc/utils"
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert"

export const DealOrderedShipmentDialog = ({toggleModal, orders, getDealOrders}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      order_number: "",
      shipment_type: "",
    },
  });

  const onSubmit = (data) => {
    console.log("Submitted:", data)
    console.log(orders);

    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/deal/ordered`, {
      order_number: data.order_number,
      orders: JSON.stringify(orders),
      shipment_type: data.shipment_type
    }, getAxiosHeaders())
    .then((response) => {
      getDealOrders();
      toggleModal("successOrderDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.order_number) {
          setError('order_number', {
            type: 'server',
            message: responseErrors.order_number
          });
        }

        if (responseErrors?.shipment_type) {
          setError('shipment_type', {
            type: 'server',
            message: responseErrors.shipment_type
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  const watchShipmentType = watch("shipment_type")

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Shipment Details</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Order Number</Label>
            <Input
              id="order_number"
              {...register("order_number", { 
                required: "Order number is required",
                pattern: {
                  value: /^[A-Za-z0-9]{6,}$/,
                  message: "Order number must be at least 6 alphanumeric characters"
                }
              })}
              placeholder="Enter order number"
            />
            {errors.order_number && (
              <p className="text-sm text-red-500">{errors.order_number.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label>Shipment Type</Label>
            <div className="flex space-x-2">
              <Button
                type="button"
                variant={watchShipmentType === "sea" ? "primaryOutline" : "outline"}
                className="flex-1"
                onClick={() => setValue("shipment_type", "sea")}
              >
                <Ship className="mr-2 h-4 w-4" />
                Sea
              </Button>
              <Button
                type="button"
                variant={watchShipmentType === "air" ? "primaryOutline" : "outline"}
                className="flex-1"
                onClick={() => setValue("shipment_type", "air")}
              >
                <Plane className="mr-2 h-4 w-4" />
                Air
              </Button>
            </div>
            <input
              type="hidden"
              {...register("shipment_type", { required: "Shipment type is required" })}
            />
            {errors.shipment_type && (
              <p className="text-sm text-red-500">{errors.shipment_type.message}</p>
            )}
          </div>
          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

