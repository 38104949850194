import React, { useState, useEffect } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { baseURLs } from "../../misc/constants";  // Adjust this import based on your project
import { getAxiosHeaders } from "../../misc/utils";  // Adjust this import based on your project

const ProductSelect = ({ field, onChange }) => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown open/close state
  const [searchQuery, setSearchQuery] = useState(""); // Search input query
  const [products, setProducts] = useState([]); // List of fetched products
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state for API calls

  useEffect(() => {
    const fetchProducts = async () => {
      
      try {
        setLoading(true);
        setError(null);
        
        const response = await axios.get(`${baseURLs.API_URL}/products`, {
          headers: getAxiosHeaders().headers,
          params: {
            product_name: searchQuery ?? "", // Search products by name
          },
        });
        
        if (response.status === 200) {
          setProducts(response.data.data.products || []);
        }
      } catch (err) {
        setError("Failed to load products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  const handleSelect = (product) => {
    setIsOpen(false); // Close dropdown
    field.onChange(product.product_id);
  };

  return (
    <div className="relative w-full">
      {/* Trigger: Displays selected product */}
      <div
        className="flex items-center gap-4 px-4 py-2 border rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {field.value ? (
          <div className="flex items-center gap-4">
            <img
              src={products.find((p) => p.product_id === field.value)?.thumbnail}
              alt={products.find((p) => p.product_id === field.value)?.product_name}
              className="w-8 h-8 rounded"
            />
            <div>
              <p className="text-sm font-medium">
                {products.find((p) => p.product_id === field.value)?.product_name}
              </p>
              <p className="text-xs text-gray-500">
                {`¥ ${products.find((p) => p.product_id === field.value)?.unit_cost} ~ GHS ${products.find((p) => p.product_id === field.value)?.ghs_cost} | MOQ: ${products.find((p) => p.product_id === field.value)?.moq}`}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-gray-400">Select a product...</p>
        )}
        <span className="ml-auto text-gray-500">▼</span>
      </div>

      {/* Dropdown */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border rounded-md shadow-md">
          {/* Search Input */}
          <div className="p-2">
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Loading Indicator */}
          {loading && (
            <div className="px-4 py-2 text-gray-500">Loading...</div>
          )}

          {/* Error Message */}
          {error && (
            <div className="px-4 py-2 text-red-500">{error}</div>
          )}

          {/* Product Options */}
          {!loading && !error && products.length > 0 && (
            <div className="max-h-60 overflow-y-auto">
              {products.map((product) => (
                <div
                  key={product.product_id}
                  className="flex items-center gap-4 px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSelect(product)}
                >
                  <img
                    src={product.thumbnail}
                    alt={product.product_name}
                    className="w-8 h-8 rounded"
                  />
                  <div>
                    <p className="text-sm font-medium">{product.product_name}</p>
                    <p className="text-xs text-gray-500">{`¥ ${product.unit_cost} ~ GHS ${product.ghs_cost} | MOQ: ${product.moq}`}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* No Products Found */}
          {products.length === 0 && !loading && !error && (
            <div className="px-4 py-2 text-gray-500">No products found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductSelect;
