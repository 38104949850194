import { Redirect } from "react-router-dom";
import moment from "moment";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogOverlay, DialogTitle } from "../components/ui/dialog";
import { X } from "lucide-react";
import { Button } from "../components/ui/button";

export const getQueryParams = (url) => {
  const queryParams = {};
  const urlObj = new URL(url);
  const urlSearchParams = new URLSearchParams(urlObj.search);

  for (const [key, value] of urlSearchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const setCookie = (name, value, days = 60) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  const cookie = `${name}=${value};expires=${expirationDate.toUTCString()};path=/`;
  document.cookie = cookie;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

export const getAuthStatus = () => {
  let access_token = getCookie('access_token');
  let adminInfo = localStorage.getItem('admin_info');
  try {
    adminInfo = JSON.parse(adminInfo);
  } catch (error) {
    adminInfo = {};
  }

  let is_logged_in = false;
  if(access_token?.length > 0 && adminInfo?.account_status === 'active'){
    is_logged_in = true;
  }

  return is_logged_in;
};

export const getAxiosHeaders = () => {
  let access_token = getCookie('access_token');
  return { headers: {"Authorization" : `Bearer ${access_token}`} }
}

export const getAxiosUploadHeaders = () => {
  let access_token = getCookie('access_token');
  return { 
      headers: {
          "Authorization" : `Bearer ${access_token}`, 
          "content-type": "multipart/form-data"
      } 
  }
}

export const OrderStatus = ({ status }) => {
  switch (status) {
    case 'requested':
      return (
        <span className="text-xs capitalize text-slate-500">
          {status}
        </span>
      );

    case 'pending_approval':
      return (
        <span className="text-xs capitalize text-orange-500">
          {status}
        </span>
      );

    case 'approved':
      return (
        <span className="text-xs capitalize text-cyan-500">
          {status}
        </span>
      );

    case 'pending_payment':
      return (
        <span className="text-xs capitalize text-yellow-500">
          pending payment
        </span>
      );

    case 'paid':
      return (
        <span className="text-xs capitalize text-green-500">
          {status}
        </span>
      );

    case 'processing':
      return (
        <span className="text-xs capitalize text-blue-500">
          {status}
        </span>
      );

    case 'ordered':
      return (
        <span className="text-xs capitalize text-gray-500">
          {status}
        </span>
      );

    case 'shipped_from_supplier':
      return (
        <span className="text-xs capitalize text-gray-500">
          shipped from supplier
        </span>
      );

    case 'at_transit_warehouse':
      return (
        <span className="text-xs capitalize text-gray-500">
          at transit warehouse
        </span>
      );

    case 'shipped':
      return (
        <span className="text-xs capitalize text-gray-500">
          {status}
        </span>
      );

    case 'ready_for_delivery':
      return (
        <span className="text-xs capitalize text-gray-500">
          ready for delivery
        </span>
      );

    case 'delivered':
      return (
        <span className="text-xs capitalize text-green-500">
          {status}
        </span>
      );

    case 'on_hold':
      return (
        <span className="text-xs capitalize text-yellow-500">
          on hold
        </span>
      );

    case 'cancelled':
      return (
        <span className="text-xs capitalize text-red-500">
          {status}
        </span>
      );

    default:
      return (
        <span className="text-xs capitalize text-gray-500">
          Unknown status
        </span>
      );
  }
};

// URL validation function
export const validateUrl = (value) => {
  console.log({value});
  const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i; // Regex for common image formats
  return urlPattern.test(value) || "Invalid image URL.";
};

export const generateVariants = (variantTypes, variants, productName, defaultCost) => {
  function helper(index, currentVariant, result) {
    if (index === variantTypes.length) {
      const variantName = currentVariant.map(v => v.option).join(" / ");

      // Check if the current variant combination already exists by comparing each type and option
      const existingVariant = variants.find((v) => 
        v.variant_type.length === currentVariant.length &&
        v.variant_type.every((vt, i) => 
          vt.type === currentVariant[i].type && vt.option === currentVariant[i].option
        )
      );

      if (existingVariant) {
        // If the variant exists, update the product_name
        existingVariant.product_name = `${productName} - (${variantName})`;
        result.push(existingVariant);
      } else {
        // If the variant doesn't exist, add it
        result.push({
          variant_id: null,
          variant_type: currentVariant,
          unit_cost: defaultCost,
          product_name: `${productName} - (${variantName})`
        });
      }
      return;
    }

    const { type, options } = variantTypes[index];
    for (const option of options) {
      // Build the current variant in the desired format
      helper(index + 1, [...currentVariant, { type: type, option: option }], result);
    }
  }

  // Initialize result array
  const result = [];
  helper(0, [], result);
  return result;
};

export const moneyToFloat = (amount) => {
  // Remove commas from the string
  const noCommas = amount.replace(/,/g, '');

  // Convert string to float
  return parseFloat(noCommas);
}

export const formatTo2DP = ( amount ) => {
  return parseFloat((Math.round(amount * 100) / 100).toFixed(2));
}

export const ImageModal = ({ open, onClose, imageUrl }) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="justify-center">
        {/* Display the Image */}
        <img
          src={imageUrl}
          alt="Selected"
          className="max-h-[500px] object-contain rounded-md"
        />
      </DialogContent>
    </Dialog>
  );
}

export const ErrorMessageModal = ({ open, onClose, message }) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Error</DialogTitle>
          <DialogDescription>
            {message}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}